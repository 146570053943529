@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value governorBay, white from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .suggestionList {
    list-style: none;
  }

  .textWithEllipsis {
    overflow: auto;
    text-overflow: ellipsis;
  }

  .categorySuggestionText {
    color: governorBay;
  }
}

@layer overrides {
  .searchSuggestion {
    display: block;
    padding: sm md;
    width: 100%;
    text-align: left;
    line-height: normal;

    &:hover,
    &:hover .categorySuggestionText,
    &:focus,
    &:active {
      cursor: pointer;
      background-color: governorBay;
      color: white;
      outline: none;
    }
  }
}
