@value searchBarSuggestionsLayer from "@/modules/styling/zIndex.module.css";
@value lightGrey1, white, boulder, blue from "@depop/web-ui-kit/theme/default/colors.module.css";
@value borderRadiusSmall from "@depop/web-ui-kit/theme/default/border.module.css";
@value breakpointMdDown, breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value xs, sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .searchSuggestionsContainer {
    position: absolute;
    z-index: searchBarSuggestionsLayer;
    background-color: white;
    top: 48px;
    left: 0;
    border-radius: borderRadiusSmall;
    box-shadow: none;
    width: 100%;
  }

  .searchSuggestionsContainer--fullViewPort {
    height: 100vh;
  }

  .suggestionListHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: sm;

    &:not(:first-of-type) {
      margin-top: 12px;
    }
  }

  @media breakpointMdUp {
    .searchSuggestionsContainer {
      background-color: lightGrey1;
      top: 0;
      left: 0;
      border-radius: 20px;
      box-shadow: 0 2px 8px -1px rgba(0, 0, 0, 0.3);
      width: 100%;
      padding-top: 40px;
      overflow: hidden;
    }

    .searchSuggestionsContainer--verticalPadding {
      left: -4px;
      width: calc(100% + 8px);
      padding-top: 56px;
    }

    .suggestionListHeader {
      padding: sm md;
    }
  }
}

@layer overrides {
  .suggestionTitle {
    display: block;
    font-weight: 700;

    @media breakpointMdDown {
      font-size: 12px;
      color: boulder;
      font-weight: 400;
    }
  }

  .suggestionClearButton {
    background: none;
    border: none;
    cursor: pointer;
    color: blue;
    line-height: normal;

    &:hover {
      opacity: 0.7;
    }

    @media breakpointMdDown {
      font-size: 12px;
    }
  }

  .searchSuggestionsContainer ul:last-child li:last-child a {
    padding-bottom: 16px;

    @media breakpointMdDown {
      padding-bottom: 0;
    }
  }
}
